<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-notes" />
            クーポン情報取込
          </CCardHeader>
          <CCardBody>
            <CRow v-show="hasError">
              <CCol>
                <CAlert show color="danger" style="white-space:pre-line;">
                  {{ errorMessage }}
                </CAlert>
              </CCol>
            </CRow>
            <CForm>
              <div style="margin:25px 0 20px 0;">
                <CInputFile
                  label="クーポン情報CSVファイル"
                  horizontal
                  @change="onChangeFiles($event)"
                />
              </div>
            </CForm>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <tr>
                    <th>メーカー名</th>
                    <th>クーポン名</th>
                    <th>JAN</th>
                    <th>割引金額</th>
                    <th>開始日</th>
                    <th>終了日</th>
                    <th>一人様1点限り</th>
                    <th>発行枚数</th>
                    <th>再取得</th>
                    <th>酒類</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="coupon in coupons" :key="coupon.index">
                    <td>{{ coupon.maker_name }}</td>
                    <td>{{ coupon.title }}</td>
                    <td>{{ coupon.jan_code }}</td>
                    <td>{{ coupon.discount }}</td>
                    <td>{{ coupon.start_date }}</td>
                    <td>{{ coupon.end_date }}</td>
                    <td>{{ coupon.onePerCustomerFlag }}</td>
                    <td>{{ coupon.limitNum }}</td>
                    <td>{{ coupon.reacquisitionFlag }}</td>
                    <td>{{ coupon.isAlcohol }}</td>
                 </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
          <CCardFooter>
            <CRow class="align-items-center">
              <CCol col="6" class="text-left">
                <CButton color="info" @click="submit" class="w-50">登録する</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from '@firebase/app';
import { uuid } from "vue-uuid";
import { required, minValue } from "vuelidate/lib/validators";

Vue.use(uuid);
const fileReader = new FileReader();

export default {
  name: "ImportForms",
  data() {
    return {
      couponOrigin: {
        id: "",
        created: "",
        created_user_id: "",
        limitNum: 0,
        discount: 0,
        enabled: true,
        end_date: "",
        image: "",
        jan_code: "",
        maker_name: "",
        modified: "",
        notice: "",
        start_date: "",
        title: "",
        subTitle: "",
        membersOnly: true,
        reacquisitionFlag: true,
        onePerCustomerFlag: true,
        status: 1,
        isAlcohol: false,
      },
      coupons: [],
      couponImage: null,
      isNew: true,
      makerPullDown: [],
      fireStorageImageUrl: "",
      dangerModal: false,
      errorRecordNumber: [],
      hasError: false,
      errorMessage: ""
    };
  },
  methods: {
    async saveCoupon(element, index) {
      // クーポン用の変数を初期化
      var coupon = JSON.parse(JSON.stringify(this.couponOrigin));

      // 表の情報をクーポンにセット
      coupon.title = element.title;

      // ID発行
      coupon.id = uuid.v4();
      coupon.created_user_id = localStorage.getItem("uid");
      coupon.sortKey = uuid.v4();

      // メーカーIDを取得(１件のみ取得されることを想定)
      var snapShot = await firebase
        .firestore()
        .collection("users")
        .where("name", "==", element.maker_name)
        .get()
      ;

      if (snapShot.docs[0] == null) {
        this.errorRecordNumber[this.errorRecordNumber.length] = index + 1;
        return;
      }

      var maker_id = snapShot.docs[0].data().id;
      coupon.maker_id = maker_id;

      // 各種フラグを設定
      coupon.reacquisitionFlag = (element.reacquisitionFlag != "");
      coupon.onePerCustomerFlag = (element.onePerCustomerFlag != "");
      coupon.isAlcohol = (element.isAlcohol != "");

      // format
      let saveData = JSON.parse(JSON.stringify(coupon));
      if (element.start_date !== "") {
        saveData.start_date = new Date(element.start_date);
      }
      if (element.end_date !== "") {
        saveData.end_date = new Date(element.end_date);
      }
      saveData.limitNum = Number(element.limitNum);
      saveData.discount = Number(element.discount);
      saveData.created = firebase.firestore.FieldValue.serverTimestamp();

      // クーポンコード生成
      const incrementsDocName = "coupon_code_" + saveData.jan_code;
      
      const doc = await firebase
        .firestore()
        .collection("increments")
        .doc(incrementsDocName)
        .get();
      
      if (doc.exists) {
        saveData.couponCode = doc.data().count + 1;
      } else {
        saveData.couponCode = 1;
      }

      // 保存
      await firebase
        .firestore()
        .collection("coupons")
        .doc(coupon.id)
        .set(saveData);

      // インクリメント更新
      await firebase
        .firestore()
        .collection("increments")
        .doc(incrementsDocName)
        .set({ count: saveData.couponCode });
    },
    async submit() {
      this.$store.commit("showLoading")
      this.errorMessage = "";
      this.errorRecordNumber = [];
      for (const[index, data] of this.coupons.entries()) {
        await this.saveCoupon(data, index);
      }

      this.$store.commit("hideLoading");

      // 保存後は一覧画面に遷移
      if (this.errorRecordNumber.length === 0) {
        const link = `/coupons`;
        this.$router.push({ path: link });
      } else {
        this.hasError = true;
        for (let element in this.errorRecordNumber) {
          this.errorMessage = this.errorMessage + this.errorRecordNumber[element] + '行目はメーカーが未登録のためクーポン登録されませんでした。\n';
        }
      }
    },
    onChangeFiles(files, e) {
      const reader = new FileReader();

      // csvの読み込みが完了したときに呼ばれる処理
      const loadCSV = () => {

        if (this.coupons.length != 0) {this.coupons = []}

        var allText = reader.result
        var lines = allText.split('\r\n')
        var beforeMakerName = ""
        lines.forEach((element, index) => {
          var columns = element.split(',')
          
          // メーカ名が空欄の時は一つ前のメーカ名を使う
          if (columns[0] != "") {beforeMakerName = columns[0]}

          // 内容確認用に表に出力する
          this.coupons.push (
            {
              maker_name: beforeMakerName,
              title: columns[1],
              jan_code: columns[2],
              discount: columns[3],
              start_date: columns[4],
              end_date: columns[5],
              onePerCustomerFlag: columns[6],
              limitNum: columns[7],
              reacquisitionFlag: columns[8],
              isAlcohol: columns[9],
            }
          )
        })
      }
      reader.onload = loadCSV
      reader.readAsText(files[0])
    },
  }
}
</script>
